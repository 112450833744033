<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nome"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="userData.name"
            />
          </b-form-group>
          <p v-if="errors && errors['name']" class="text-danger"><small>{{ errors['name'][0] }}</small></p>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              type="email"
              v-model="userData.email"
            />
          </b-form-group>
          <p v-if="errors && errors['email']" class="text-danger"><small>{{ errors['email'][0] }}</small></p>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Gênero"
            label-for="gender"
          >
            <b-form-select
              id="gender"
              :options="genderOptions"
              v-model="userData.genre.id"
            />
            <!-- <v-select
              v-model="userData.genre_id"
              :options="genderOptions"
              :reduce="val => val.id"
              :clearable="false"
              input-id="gender"
            /> -->
          </b-form-group>
          <p v-if="errors && errors['genre_id']" class="text-danger"><small>{{ errors['genre_id'][0] }}</small></p>
        </b-col>

        <!-- Field: Birth -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nascimento"
            label-for="birth"
          >
            <b-form-input
              disabled
              id="birth"
              v-mask="'##/##/####'"
              type="text"
              placeholder="dd/mm/aaaa"
            />
          </b-form-group>
          <!-- v-model="currentBirth" -->
          <p v-if="errors && errors['birth']" class="text-danger"><small>{{ errors['birth'][0] }}</small></p>
        </b-col>

        <!-- Field: cpf -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="CPF"
            label-for="cpf"
          >
            <b-form-input
              disabled
              id="cpf"
              v-mask="'###########'"
              type="text"
              placeholder="00000000000"
            />
          </b-form-group>
          <p v-if="errors && errors['cpf']" class="text-danger"><small>{{ errors['cpf'][0] }}</small></p>
        </b-col>
        <!-- v-model="userData.cpf" -->

        <!-- Field: cpf -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Celular (inclui DDD)"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-mask="'###########'"
              type="text"
              v-model="userData.mobile_phone"
            />
          </b-form-group>
          <p v-if="errors && errors['mobile_phone']" class="text-danger"><small>{{ errors['mobile_phone'][0] }}</small></p>
        </b-col>
        <!-- Field: doc frente -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Documento (Frente)"
            label-for="df"
          >
            <b-form-file
              id="df"
              accept="image/*"
              v-model="docFront"
              placeholder="Selecione um arquivo ou arraste aqui..."
              drop-placeholder="Solte aqui..."
            />
          </b-form-group>
          <p v-if="errors && errors['doc']" class="text-danger"><small>{{ errors['doc'][0] }}</small></p>
        </b-col>
        <!-- Field: doc frente -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Documento (Verso)"
            label-for="dv"
          >
            <b-form-file
              id="dv"
              accept="image/*"
              v-model="docVerso"
              placeholder="Selecione um arquivo ou arraste aqui..."
              drop-placeholder="Solte aqui..."
            />
          </b-form-group>
          <p v-if="errors && errors['doc']" class="text-danger"><small>{{ errors['doc'][0] }}</small></p>
        </b-col>
        <!-- Field: doc frente -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Selfie de comprovação"
            label-for="vc"
          >
            <b-form-file
              id="vc"
              accept="image/*"
              v-model="confirmation_media"
              placeholder="Selecione um arquivo ou arraste aqui..."
              drop-placeholder="Solte aqui..."
            />
          </b-form-group>
          <p v-if="errors && errors['confirmation_media']" class="text-danger"><small>{{ errors['confirmation_media'][0] }}</small></p>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="saveUser"
    >
      Salvar
    </b-button>
    <b-link
      href="/apps/users/list"
      variant="outline-secondary"
    >
      Voltar
    </b-link>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormSelect, BFormFile, BLink,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'
import useUsersList from '../users-list/useUsersList'

Vue.directive('mask', VueMaskDirective)

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormFile,
    BFormSelect,
    BLink,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      docFront: null,
      docVerso: null,
      confirmation_media: null,
      currentBirth: null,
    }
  },
  mounted() {
    /* eslint-disable */
    /* if (this.userData.birth) {
      this.currentBirth = this.userData.birth.split('-')[2].split('T')[0] + '/' + this.userData.birth.split('-')[1] + '/' + this.userData.birth.split('-')[0]
    } */
    /* eslint-enable */
  },
  methods: {
    saveUser() {
      const formData = new FormData()
      formData.append('_method', 'PUT')
      if (this.userData.name) {
        formData.append('name', this.userData.name)
      }
      if (this.userData.email) {
        formData.append('email', this.userData.email)
      }
      if (this.userData.genre.id) {
        formData.append('genre_id', this.userData.genre.id)
      }
      /* if (this.currentBirth) {
        const dateSplit = this.currentBirth.split('/')
        formData.append('birth', `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`)
      } */
      /* if (this.userData.cpf) {
        formData.append('cpf', this.userData.cpf)
      } */
      if (this.confirmation_media) {
        formData.append('confirmation_media', this.confirmation_media)
      }
      if (this.docFront) {
        formData.append('doc[]', this.docFront)
      }
      if (this.docVerso) {
        formData.append('doc[]', this.docVerso)
      }
      if (this.userData.mobile_phone) {
        formData.append('mobile_phone', this.userData.mobile_phone)
      }
      const data = {
        id: this.userData.id,
        payload: formData,
      }
      this.$emit('sendSubmit', data)
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const genderOptions = [
      { text: 'Homem', value: 1 },
      { text: 'Mulher', value: 2 },
      { text: 'Travestis & Transex', value: 3 },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      genderOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
